import {
  collection,
  query,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  where,
} from 'firebase/firestore';
import { db } from './init';

const collectionName = 'cvs';

const CVs = {};

CVs.getAll = async () => {
  const result = [];
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    var data = doc.data();
    result.push({ ...data, id: doc.id });
  });

  return result;
};

CVs.findByHash = async (hash) => {
  var result = undefined;

  const q = query(collection(db, collectionName), where('hash', '==', hash));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    var data = doc.data();
    result = { ...data, id: doc.id };
  });

  return result;
};

CVs.findById = async (id) => {
  var result = undefined;

  const docRef = doc(db, collectionName, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    result = { ...data, id };
  }

  return result;
};

CVs.add = async (file) => {
  var newFile = undefined;

  const docRef = await addDoc(collection(db, collectionName), file);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    var data = docSnap.data();
    newFile = { ...data, id: docSnap.id };
  }

  return newFile;
};

CVs.deleteById = async (id) => {
  await deleteDoc(doc(db, collectionName, id));
};

export default CVs;
