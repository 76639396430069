export default class WrongEmailOrPasswordError extends Error {
  constructor(message = '') {
    super();
    this.response = {
      data: {
        message,
      },
    };
  }
}
