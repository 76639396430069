class User {
  constructor(loggedInUserObj, userFirebaseModel) {
    var combinedObj = { ...loggedInUserObj, ...userFirebaseModel };

    Object.keys(combinedObj).forEach((key) => {
      this[key] = combinedObj[key];
    });
  }
}

export default User;
