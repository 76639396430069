import Auth from './auth';
import Storage from './storage';
import CVs from './cvs';
import Users from './users';

const FirebaseService = {
  Auth,
  Storage,
  CVs,
  Users,
};

export default FirebaseService;
