import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(
      () => import('views/auth-views/authentication/login'),
    ),
  },
  {
    key: 'register',
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(
      () => import('views/auth-views/authentication/register'),
    ),
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(
      () => import('views/auth-views/authentication/forgot-password'),
    ),
  },
];

export const adminRoutes = [
  // {
  //   key: 'upload',
  //   path: `${APP_PREFIX_PATH}/upload/*`,
  //   component: React.lazy(() => import('views/app-views/upload')),
  // },
  {
    key: 'profile',
    path: `${APP_PREFIX_PATH}/profile`,
    component: React.lazy(() => import('views/app-views/profile')),
  },
  {
    key: 'job-recommendations',
    path: `${APP_PREFIX_PATH}/job-recommendations`,
    component: React.lazy(() => import('views/app-views/job-recommendations')),
  },
];
