import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check';
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import defaultConfig from './firebase-config.json';

// testing workflow configuration 3
// import { getAnalytics } from "firebase/analytics";

var configString = process.env.REACT_APP_FIREBASE_CONFIG;
var config = configString ? JSON.parse(configString) : defaultConfig;

// Initialize Firebase
const app = initializeApp(config);
// const analytics = getAnalytics(app);

const storage = getStorage(app);

const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

const db = getFirestore(app);
/* const appCheckKey =
  process.env.REACT_APP_CHECK_KEY || '6LfOa1IoAAAAAKH3b4uVlPQZuVYaGZ1mchf5gOsl';

try {
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(appCheckKey),
    isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
  });
} catch (err) {} */

export { app, storage, auth, db };
