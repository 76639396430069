import { combineReducers } from 'redux';
import auth from './slices/authSlice';
import participants from './slices/participantsSlice';
import supervisors from './slices/supervisorsSlice';
import theme from './slices/themeSlice';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    participants,
    supervisors,
    theme,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
