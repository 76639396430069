import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Supervisor from 'models/firebase/Supervisor';
import FirebaseService from 'services/FirebaseService';
const { Users } = FirebaseService;

export const initialState = {
  supervisors: [],
};

export const fetch = createAsyncThunk(
  'supervisors/fetch',
  async (data, { rejectWithValue, getState }) => {
    try {
      const user = getState().auth.user;
      const users = await Users.findByCompanyId(user.companyId);

      return [...users].map((s) => new Supervisor(s));
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.supervisors = action.payload;
    });
  },
});

export const { fetched } = authSlice.actions;

export default authSlice.reducer;
