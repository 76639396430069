class Base {
  constructor(firestoreDocument) {
    if (firestoreDocument) {
      this.fromFirestore(firestoreDocument);
    }
  }

  fromFirestore(firestoreDocument) {
    Object.keys(firestoreDocument).forEach((key) => {
      const camelCaseKey = this.snakeToCamel(key);
      this[camelCaseKey] = firestoreDocument[key];
    });
  }

  toFirestore() {
    const firestoreDocument = {};
    for (const key in this) {
      if (this.hasOwnProperty(key) && key !== 'id') {
        const snakeCaseKey = this.camelToSnake(key);
        firestoreDocument[snakeCaseKey] = this[key];
      }
    }
    return firestoreDocument;
  }

  snakeToCamel(s) {
    return s.replace(/(_\w)/g, (m) => m[1].toUpperCase());
  }

  camelToSnake(s) {
    return s.replace(/([A-Z0-9])/g, '_$1').toLowerCase();
  }
}

export default Base;
