import Base from './Base';

class Participant extends Base {
  constructor(firestoreDocument) {
    super(firestoreDocument);
    this.fullName = this.firstName + ' ' + this.lastName;
    this.status = this.#getStatus();
  }

  #getStatus() {
    if (this.interruption && this.interruption !== '') return 'Avbrott';

    if (this.#todayisEqualOrLater(this.startDate) && this.started !== 'yes') {
      return 'Ej startad';
    } else if (
      this.#todayisEqualOrLater(this.startDate) &&
      !this.finalReportRegistered
    ) {
      return 'Aktiv';
    } else if (this.finalReportRegistered && !this.finalReportAttested) {
      return 'Inväntar slutattestering';
    } else if (this.finalReportRegistered) {
      return 'Avslutad';
    }

    return 'Inskriven';
  }

  #todayisEqualOrLater(dateStr) {
    const date = new Date(dateStr);
    const today = new Date();

    return today.getTime() >= date.getTime();
  }
}

export default Participant;
