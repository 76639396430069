import Base from './Base';

class Supervisor extends Base {
  constructor(firestoreDocument) {
    super(firestoreDocument);
    this.fullName = this.firstName + ' ' + this.lastName;
  }
}

export default Supervisor;
